<template>
  <div>
    <!-- promo mobile -->
    <a
      href="#"
      id="btn-promo"
      v-scroll-to="{ el: '#element', duration: 1500 }"
      class="lg:hidden"
    >
      <div class="fixed z-30 cursor-pointer left-1 bottom-2">
        <img src="/images/promo.png" alt="" class="w-44" />
      </div>
    </a>
    <!-- end promo mobile -->
    <LanguageSelect />

    <!-- header -->
    <MainBanner />
    <!-- Model Photocopier -->
    <div class="py-8 bg-gray-50">
      <div class="container">
        <h1
          class="text-xl font-medium text-center lg:text-2xl md:text-2xl text-dbm-gray"
        >
          Photocopier Machine <span class="text-dbm-red"> Models</span>
        </h1>
        <div class="pt-5 md:flex md:flex-wrap">
          <div
            v-for="(item, i) in models"
            :key="i"
            class="md:w-1/2 lg:w-1/4 lg:p-1"
          >
            <img
              :src="item.image"
              :alt="item.alt"
              class="w-2/3 mx-auto lg:w-full"
            />
          </div>
        </div>
        <div class="pt-6">
          <h1 class="text-xl font-medium text-center md:text-2xl text-dbm-gray">
            <span class="text-dbm-red">Features</span>
          </h1>
          <div
            class="flex flex-wrap justify-center pt-5 lg:mx-auto lg:max-w-3xl"
          >
            <div
              v-for="(item, i) in features"
              :key="i"
              class="w-1/2 md:w-1/3 lg:w-1/5"
            >
              <img :src="item.image" :alt="item.alt" class="mx-auto" />
              <h1 class="text-sm font-medium text-center text-dbm-gray">
                {{ item.alt }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Brands -->
    <div class="py-8">
      <div class="container">
        <h1
          class="text-xl font-medium text-center lg:text-2xl md:text-2xl text-dbm-gray"
        >
          Multifunction Photocopier <span class="text-dbm-red">Brands</span>
        </h1>
        <div
          class="flex flex-wrap pt-4 md:items-center lg:mx-auto lg:max-w-3xl"
        >
          <div v-for="(item, i) in brands" :key="i" class="w-1/2 md:w-1/5">
            <img :src="item.image" :alt="item.alt" class="" />
          </div>
        </div>
        <a
          href="#"
          v-scroll-to="{ el: '#element', duration: 1500 }"
          class="block py-2 animate-pulse md:px-2"
          id=""
        >
          <div
            class="py-3 mx-auto text-base font-bold text-center text-white rounded-lg lg:w-72 lg:text-lg w-60 bg-dbm-red md:mx-auto"
          >
            Rental From RM58*/Month
          </div>
        </a>
      </div>
    </div>

    <!-- Rental Package -->
    <div
      class="py-8 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-rental-package.jpg')"
    >
      <div class="container">
        <h1
          class="text-xl font-medium text-center text-white lg:text-3xl md:text-2xl"
        >
          Choose a Plan That's Right for Your Business
        </h1>
        <!-- packages -->
        <div class="pt-6 pb-6 lg:pt-12 md:flex md:flex-wrap lg:flex-nowrap">
          <div
            v-for="(item, i) in packages"
            :key="i"
            :class="item.customWidth"
            class="w-full pb-3 mx-auto text-center md:p-1 lg:p-0"
          >
            <div
              :class="item.cusBorder"
              class="bg-white border border-gray-100 lg:my-6"
            >
              <div
                :class="item.bgCustom"
                class="h-16 py-5 text-lg font-semibold text-white text-shadow"
              >
                <h1 v-if="item.popular" class="text-2xl font-bold text-white lg:text-xl">
                  {{ item.popular }}
                </h1>
              </div>
              <div :class="item.borderCustom" class="border-t-2 border-b-2">
                <h1 :class="item.textCustom" class="py-6 text-5xl font-bold">
                  {{ item.price }}
                </h1>
              </div>
              <div>
                <ul
                  class="py-5 text-sm font-medium text-gray-600 md:px-2 md:h-100 lg:h-110"
                >
                  <li
                    v-for="list in item.Package"
                    :key="list.support"
                    class="pb-1"
                  >
                    {{ list.support }}
                  </li>
                  <img
                    v-if="item.image"
                    :src="item.image"
                    :alt="item.alt"
                    class="w-3/4 pt-3 mx-auto"
                  />
                  <div v-if="item.recomended" class="flex items-center">
                    <div
                      v-for="image in item.recomended"
                      :key="image"
                      class="p-1 mx-auto"
                    >
                      <img
                        :src="image"
                        alt=""
                        class="mx-auto"
                        :class="image.resize"
                      />
                    </div>
                  </div>
                </ul>
              </div>
              <div class="pt-6">
                <h1
                  v-html="item.promo"
                  :class="item.bgCustom"
                  class="py-6 text-xl font-bold leading-tight text-white text-shadow md:flex md:items-center md:justify-center md:h-28"
                >
                  {{ item.promo }}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <h1 class="pt-4 text-base text-center text-white">
          *Terms and condition apply.
        </h1>
      </div>
    </div>

    <!-- your right choice -->
    <div class="py-8 bg-gray-50">
      <div class="container">
        <h1 class="text-xl font-medium text-center lg:text-2xl text-dbm-gray">
          Our <span class="text-dbm-red">Advantages</span>
        </h1>
        <div
          class="pt-6 md:flex md:flex-wrap lg:max-w-3xl lg:mx-auto xl:max-w-4xl"
        >
          <div
            v-for="(item, i) in advantage"
            :key="i"
            class="w-2/3 pb-2 mx-auto md:px-1 md:w-1/3"
          >
            <div class="py-5 text-center bg-white shadow-lg">
              <img :src="item.image" :alt="item.alt" class="mx-auto" />
              <h1
                v-html="item.alt"
                class="pt-6 text-base font-medium leading-tight md:h-16 text-dbm-gray"
              >
                {{ item.alt }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- clienteles -->
    <div class="py-10">
      <div class="container">
        <h1
          class="pt-3 text-xl font-medium text-center lg:text-2xl text-dbm-gray"
        >
          Our Trusted <span class="text-dbm-red">Clients</span>
        </h1>
        <p class="pt-4 pb-6 text-sm text-center text-gray-600">
          What are you waiting for? Come on board with us to be one of our happy
          clients too!
        </p>

        <carousel
          :autoplay="true"
          :loop="true"
          :dots="false"
          :responsive="{
            0: { items: 1, nav: false },
            600: { items: 4, nav: false },
            1000: { items: 5, nav: false },
          }"
        >
          <!--carousel area-->
          <div v-for="image in clienteles" :key="image" class="px-2">
            <img :src="image" alt="" class="customImg" />
          </div>
        </carousel>

        <div class="bg-gray-100 my-2 w-full h-0.5"></div>
        <carousel
          :autoplay="true"
          :loop="true"
          :dots="false"
          :responsive="{
            0: { items: 1, nav: false },
            600: { items: 4, nav: false },
            1000: { items: 5, nav: false },
          }"
        >
          <!--carousel area-->
          <div v-for="image in clienteles_2" :key="image" class="px-2">
            <img :src="image" alt="" class="customImg" />
          </div>
        </carousel>
      </div>
    </div>

    <!-- about us -->
    <div
      class="py-8 bg-left bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-about.jpg')"
    >
      <div class="container">
        <div class="md:flex md:items-center">
          <div class="md:w-1/2">
            <img src="/images/about-us.jpg" alt="" class="mx-auto lg:w-2/3" />
          </div>
          <div class="pt-6 md:w-1/2 md:pt-0">
            <div
              class="font-medium text-center md:text-left md:pl-3 text-dbm-gray"
            >
              <h1 class="text-xl text-dbm-red md:text-2xl">About Us</h1>
              <div
                class="pt-5 text-sm font-light leading-tight lg:text-base text-dbm-gray"
              >
                <p class="pb-2">
                  <span class="font-bold">DBM Solution (M) Sdn Bhd</span> was
                  established since 2009 with our focus on
                  <span class="font-bold">Document Management Solutions</span>
                  that combines
                  <span class="font-bold"
                    >innovative technology and high-quality service</span
                  >
                  for both new and reconditioned photocopier machine market. We
                  shall strive to provide the ideal and cost-effective
                  photocopier solution for your company needs.
                </p>
                <p class="pb-2">
                  We are an authorised dealer of Konica Minolta Business
                  Solutions Sdn Bhd. Our founder has more than
                  <span class="font-bold">20 years of experience</span> in
                  providing photocopying solutions for offices and our staffs
                  are skilled with the necessary knowledge in our range of
                  products and services which enable us to produce superior work
                  quality.
                </p>
                <p>
                  Our clientele ranges from the hotel, education, engineering,
                  travel, advertisement, accounting firm, lawyer firm, training
                  company, trading company, event company, multi-level marketing
                  company and various other industries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- enquiry section -->
    <div id="element" class="py-10 bg-dbm-red">
      <div class="container">
        <div class="lg:max-w-3xl lg:mx-auto">
          <h1 class="text-xl font-medium leading-tight text-center text-white">
            Get Free Quote Immediately for Our Photocopier!
          </h1>
          <p class="pt-4 text-sm text-center text-white">
            Still unsure of which model would best suit you?<br />
            <br />
            Simply fill up the form to get a quick quote from us, you’ll be
            entitled to a FREE online consultation!
          </p>
          <!-- feedback.activamedia.com.sg script begins here -->
          <div id="enquire" class="pt-4">
            <iframe
              allowTransparency="true"
              style="min-height: 530px; height: inherit; overflow: auto"
              width="100%"
              id="contactform123"
              name="contactform123"
              marginwidth="0"
              marginheight="0"
              frameborder="0"
              src="https://feedback.activamedia.com.sg/my-contact-form-5953452.html"
              ><p>
                Your browser does not support iframes. The contact form cannot
                be displayed. Please use another contact method (phone, fax etc)
              </p></iframe
            >
          </div>
          <!-- feedback.activamedia.com.sg script ends here -->
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="py-8">
      <div class="container">
        <div>
          <img
            src="/images/main-logo.png"
            alt="myGD Express"
            class="w-2/3 mx-auto md:w-1/3 lg:w-80"
          />
          <p
            class="pt-3 text-xl font-bold text-center lg:text-2xl text-dbm-gray"
          >
            DBM Solution (M) Sdn Bhd
          </p>
          <div class="py-4">
            <p class="text-lg font-medium text-center lg:texl-xl text-dbm-gray">
              Wisma DBM,No.2, Jalan PJS 6/6B, Desa Mentari, 46150 Petaling Jaya, Selangor
            </p>
            <p class="pt-3 text-lg font-semibold text-center text-dbm-gray">
              Call Us At :
              <a href="tel:+60378658533" class="hover:text-dbm-red"
                >03-78658533
              </a>
              |
              <a href="tel:+60378658611" class="hover:text-dbm-red"
                >03-78658611
              </a>
              |
              <a href="tel:+60378659599" class="hover:text-dbm-red"
                >03-78659599
              </a>
            </p>
            <p class="pt-2 text-lg font-semibold text-center text-dbm-gray">
              <a href="https://wa.me/60195753688" target="_blank"
                ><i class="text-green-800 fab fa-whatsapp-square"></i>
                019-5753688</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- end footer -->
    <div class="container">
      <div class="py-3 border-t-2 border-gray-600 border-solid">
        <p class="font-sans text-xs text-center">
          Website maintained by Activa Media. All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import LanguageSelect from "@/components/LanguageSelect.vue";
import MainBanner from "@/components/MainBanner.vue";
import carousel from "vue-owl-carousel";

export default {
  name: "Home",
  components: {
    LanguageSelect,
    MainBanner,
    carousel,
  },
  data() {
    return {
      models: [
        { image: "/images/model-1.png", alt: "" },
        { image: "/images/model-2.png", alt: "" },
        { image: "/images/model-3.png", alt: "" },
        { image: "/images/model-4.png", alt: "" },
      ],
      features: [
        { image: "/images/icon_01.png", alt: "A3/A4 Laser Copy" },
        { image: "/images/icon_02.png", alt: "Network Scan" },
        { image: "/images/icon_03.png", alt: "Mailbox/Document Server" },
        { image: "/images/icon_04.png", alt: "Super G3/Lan Fax" },
        { image: "/images/icon_05.png", alt: "Mobile Print/Scan" },
      ],
      brands: [
        { image: "/images/brand-1.png", alt: "" },
        { image: "/images/brand-2.png", alt: "" },
        { image: "/images/brand-3.png", alt: "" },
        { image: "/images/brand-4.png", alt: "" },
        { image: "/images/brand-5.png", alt: "" },
      ],
      packages: [
        // {
        //   customWidth: "md:w-1/2 lg:w-1/4",
        //   bgCustom: "bg-gradient-to-r from-orange to-lite-orange",
        //   borderCustom: "border-orange",
        //   textCustom: "text-orange",
        //   price: "RM 38*",
        //   Package: [
        //     { support: "Color / Black & White" },
        //     { support: "Copy / Network Print / Scan / Fax*" },
        //     { support: "Support A4 & A3 Size" },
        //     { support: "Mobile Printing" },
        //     { support: "USB Direct Print / Scan" },
        //     { support: "Can Upgrade in future" },
        //     { support: "Free Service & Maintaince" },
        //     { support: "Free Machine Training" },
        //   ],
        //   image: "/images/promo-banner.jpg",
        //   promo: "SPECIAL PROMOTION",
        // },
        {
          customWidth: "md:w-1/2 lg:w-1/4",
          bgCustom: "bg-gradient-to-r from-d-blue to-lite-blue",
          borderCustom: "border-d-blue",
          textCustom: "text-d-blue",
          price: "RM 58*",
          Package: [
            { support: "Color / Black & White" },
            { support: "Copy / Network Print / Scan / Fax*" },
            { support: "Support A4 & A3 Size" },
            { support: "Mobile Printing" },
            { support: "USB Direct Print / Scan" },
            { support: "Can Upgrade in future" },
            { support: "Free Delivery" },
            { support: "Free Service & Maintenance" },
            { support: "Free Machine Training" },
          ],

          promo: "Entry Level <br> Low Usage",
        },
        {
          popular: "Most Popular",
          cusBorder: "lg:transform lg:scale-y-110 shadow-xl",
          customWidth: "md:w-1/2 lg:w-1/3",
          bgCustom: "bg-gradient-to-r from-dgreen to-lite-green",
          borderCustom: "border-dgreen",
          textCustom: "text-dgreen",
          price: "RM 98*",
          Package: [
            { support: "Color / Black & White" },
            { support: "Copy / Network Print / Scan / Fax*" },
            { support: "Support A4 & A3 Size" },
            { support: "Mobile Printing" },
            { support: "USB Direct Print / Scan" },
            { support: "Can Upgrade in future" },
            { support: "Free Delivery" },
            { support: "Free Service & Maintenance" },
            { support: "Free Machine Training" },
            {
              support:
                "Manage/Control Printing Cost with individual Quota Setting",
            },
          ],
          promo: "Business Level <br> Medium Usage",
          recomended: ["/images/recomended-1.png"],
        },
        {
          customWidth: "md:w-1/2 lg:w-1/4",
          bgCustom: "bg-gradient-to-r from-dark-purple to-lite-purple",
          borderCustom: "border-dark-purple",
          textCustom: "text-dark-purple",
          price: "RM 138*",
          Package: [
            { support: "Color / Black & White" },
            { support: "Copy / Network Print / Scan / Fax*" },
            { support: "Support A4 & A3 Size" },
            { support: "Mobile Printing" },
            { support: "USB Direct Print / Scan" },
            { support: "Can Upgrade in future" },
            { support: "Free Delivery" },
            { support: "Free Service & Maintenance" },
            { support: "Free Machine Training" },
            { support: "High Speed Heavy Duty Colour Multifunction" },
            {
              support:
                "Manage/Control Printing Cost with individual Quota Setting",
            },
          ],

          promo: "Enterprise Level <br> Heavy Usage",
        },
        {
          popular: "Value For Money",
          customWidth: "md:w-1/2 lg:w-1/4",
          bgCustom: "bg-gradient-to-r from-orange to-lite-orange",
          borderCustom: "border-orange",
          textCustom: "text-orange",
          price: "RM 168*",
          Package: [
            { support: "Color / Black & White" },
            { support: "Copy / Network Print / Scan / Fax*" },
            { support: "Support A4 & A3 Size" },
            { support: "Mobile Printing" },
            { support: "USB Direct Print / Scan" },
            { support: "Can Upgrade in future" },
            { support: "Free Delivery" },
            { support: "Free Service & Maintenance" },
            { support: "Free Machine Training" },
            { support: "High Speed Heavy Duty Colour Multifunction" },
            {
              support:
                "Manage/Control Printing Cost with individual Quota Setting",
            },
            {
              support: " High Speed Printing ",
            },
          ],
          promo: "Enterprise Level <br> Super Heavy Usage",
          recomended: ["/images/promo-2.png"],
        },
      ],
      advantage: [
        {
          image: "/images/advan-1.png",
          alt: "Low Operating costs",
        },
        {
          image: "/images/advan-2.png",
          alt: "Accept Trade In",
        },
        {
          image: "/images/advan-3.png",
          alt: "Customer <br> Satisfaction 99%",
        },
        {
          image: "/images/advan-4.png",
          alt: "Over 15 Years of<br> Industry Experience",
        },
        {
          image: "/images/advan-5.png",
          alt: "Authorized<br> Premium Dealer",
        },
        {
          image: "/images/advan-6.png",
          alt: "Over 23 Years<br> Expertise",
        },
      ],
      clienteles: [
        "/images/clients/client-1.png",
        "/images/clients/client-2.png",
        "/images/clients/client-3.png",
        "/images/clients/client-4.png",
        "/images/clients/client-5.png",
        "/images/clients/client-6.png",
        "/images/clients/client-7.png",
        "/images/clients/client-8.png",
        "/images/clients/client-9.png",
        "/images/clients/client-10.png",
      ],
      clienteles_2: [
        "/images/clients/client-11.png",
        "/images/clients/client-12.png",
        "/images/clients/client-13.png",
        "/images/clients/client-14.png",
        "/images/clients/client-15.png",
        "/images/clients/client-16.png",
        "/images/clients/client-17.png",
        "/images/clients/client-18.png",
        "/images/clients/client-19.png",
        "/images/clients/client-20.png",
      ],
    };
  },
};
</script>

<style>
.txt-shadow {
  text-shadow: 2px 2px 2px #000;
}
.text-shadow {
  text-shadow: 2px 2px 5px #151515;
}
.customImg {
  width: 70% !important;
  margin: 0 auto;
}
@media (min-width: 768px) {
  /* .bgCustom {
    background-position-x: 40%;
  } */
  /* .hCustom {
    height: 570px;
  } */
  #enquire iframe {
    min-height: 460px !important;
  }
  .customImg {
    width: 100% !important;
  }
}
</style>
