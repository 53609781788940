<template>
  <div>
      <div class="py-4">
      <div class="container">
        <div class="relative py-10 lg:py-20 lg:flex lg:flex-wrap">
          <div class="lg:ml-auto lg:w-2/3 lg:order-2">
            <img
              src="/images/main-banner-mobile.png"
              alt="Dbm Solution Sdn Bhd"
              class="w-full"
            />
          </div>
          <div class="pt-4 lg:absolute lg:w-1/2 lg:order-1">
            <img
              src="/images/main-logo.png"
              alt="DBM Solution Photocopier"
              class="w-2/3 mx-auto md:w-1/3 lg:mx-0 lg:w-72"
            />
            <div class="py-6">
              <h1
                class="pb-3 text-3xl font-semibold leading-none text-center md:text-5xl lg:text-3xl lg:text-left text-dbm-gray"
              >
                1 Stop Photocopier Solution <br class="hidden lg:block"> Specialist in
                <span class="text-dbm-red">Malaysia</span>
              </h1>
              <h1
                class="py-2 text-xl font-normal leading-none text-center lg:text-left text-dbm-gray md:leading-tight"
              >
                Rental From
                <span class="font-bold text-dbm-red">RM58*/Month</span>
              </h1>
            </div>
            <div class="lg:flex lg:pt-3">
              <a
                v-if="!hideEnquiry"
                href="#"
                v-scroll-to="{ el: '#element', duration: 1500 }"
                class="block py-2 mx-auto mb-1 text-base text-center text-white uppercase rounded-lg lg:mr-1 lg:mb-0 w-52 bg-dbm-red lg:mx-0"
                >Enquire Us Now!</a
              >
              <a
                href="tel:+60195753688"
                class="block py-2 mx-auto text-base text-center text-white rounded-lg w-52 bg-dbm-gray lg:mx-0"
                >CALL US : 019-575 3688</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>

</style>